// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-advantage-js": () => import("./../../../src/pages/add-advantage.js" /* webpackChunkName: "component---src-pages-add-advantage-js" */),
  "component---src-pages-add-advice-js": () => import("./../../../src/pages/add-advice.js" /* webpackChunkName: "component---src-pages-add-advice-js" */),
  "component---src-pages-advantage-js": () => import("./../../../src/pages/advantage.js" /* webpackChunkName: "component---src-pages-advantage-js" */),
  "component---src-pages-advice-js": () => import("./../../../src/pages/advice.js" /* webpackChunkName: "component---src-pages-advice-js" */),
  "component---src-pages-adviceinfo-js": () => import("./../../../src/pages/adviceinfo.js" /* webpackChunkName: "component---src-pages-adviceinfo-js" */),
  "component---src-pages-advices-js": () => import("./../../../src/pages/advices.js" /* webpackChunkName: "component---src-pages-advices-js" */),
  "component---src-pages-auther-profile-js": () => import("./../../../src/pages/auther-profile.js" /* webpackChunkName: "component---src-pages-auther-profile-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-choose-deals-desktop-js": () => import("./../../../src/pages/choose_deals_desktop.js" /* webpackChunkName: "component---src-pages-choose-deals-desktop-js" */),
  "component---src-pages-choose-deals-mobile-js": () => import("./../../../src/pages/choose_deals_mobile.js" /* webpackChunkName: "component---src-pages-choose-deals-mobile-js" */),
  "component---src-pages-company-exist-js": () => import("./../../../src/pages/company-exist.js" /* webpackChunkName: "component---src-pages-company-exist-js" */),
  "component---src-pages-feed-js": () => import("./../../../src/pages/feed.js" /* webpackChunkName: "component---src-pages-feed-js" */),
  "component---src-pages-folders-js": () => import("./../../../src/pages/folders.js" /* webpackChunkName: "component---src-pages-folders-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indexback-js": () => import("./../../../src/pages/indexback.js" /* webpackChunkName: "component---src-pages-indexback-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-modal-four-js": () => import("./../../../src/pages/modal-four.js" /* webpackChunkName: "component---src-pages-modal-four-js" */),
  "component---src-pages-modalside-js": () => import("./../../../src/pages/modalside.js" /* webpackChunkName: "component---src-pages-modalside-js" */),
  "component---src-pages-modalside-two-js": () => import("./../../../src/pages/modalside-two.js" /* webpackChunkName: "component---src-pages-modalside-two-js" */),
  "component---src-pages-newpasswordrec-js": () => import("./../../../src/pages/newpasswordrec.js" /* webpackChunkName: "component---src-pages-newpasswordrec-js" */),
  "component---src-pages-oups-js": () => import("./../../../src/pages/oups.js" /* webpackChunkName: "component---src-pages-oups-js" */),
  "component---src-pages-partner-profile-js": () => import("./../../../src/pages/partner-profile.js" /* webpackChunkName: "component---src-pages-partner-profile-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-preferences-js": () => import("./../../../src/pages/preferences.js" /* webpackChunkName: "component---src-pages-preferences-js" */),
  "component---src-pages-privacy-terms-fr-js": () => import("./../../../src/pages/privacy-terms-fr.js" /* webpackChunkName: "component---src-pages-privacy-terms-fr-js" */),
  "component---src-pages-privacy-terms-js": () => import("./../../../src/pages/privacy-terms.js" /* webpackChunkName: "component---src-pages-privacy-terms-js" */),
  "component---src-pages-privacy-terms-nl-js": () => import("./../../../src/pages/privacy-terms-nl.js" /* webpackChunkName: "component---src-pages-privacy-terms-nl-js" */),
  "component---src-pages-recover-js": () => import("./../../../src/pages/recover.js" /* webpackChunkName: "component---src-pages-recover-js" */),
  "component---src-pages-request-info-js": () => import("./../../../src/pages/request-info.js" /* webpackChunkName: "component---src-pages-request-info-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-sign-up-three-js": () => import("./../../../src/pages/sign-up-three.js" /* webpackChunkName: "component---src-pages-sign-up-three-js" */),
  "component---src-pages-sign-up-two-js": () => import("./../../../src/pages/sign-up-two.js" /* webpackChunkName: "component---src-pages-sign-up-two-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-site-terms-fr-js": () => import("./../../../src/pages/site-terms-fr.js" /* webpackChunkName: "component---src-pages-site-terms-fr-js" */),
  "component---src-pages-site-terms-nl-js": () => import("./../../../src/pages/site-terms-nl.js" /* webpackChunkName: "component---src-pages-site-terms-nl-js" */),
  "component---src-pages-user-check-info-js": () => import("./../../../src/pages/user-check-info.js" /* webpackChunkName: "component---src-pages-user-check-info-js" */),
  "component---src-pages-user-check-info-two-js": () => import("./../../../src/pages/user-check-info-two.js" /* webpackChunkName: "component---src-pages-user-check-info-two-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

